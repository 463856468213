import { useId } from 'react';
import tw, { styled } from 'twin.macro';

import { LightbulbIcon } from './LightbulbIcon';
import { WarningIcon } from './WarningIcon';

const icons = {
	lightbulb: LightbulbIcon,
	warning: WarningIcon,
};

const StyledIcon = styled('svg', {
	variants: {
		color: {
			blue: tw`[--icon-foreground:theme(colors.slate.900)] [--icon-background:theme(colors.white)]`,
			amber: tw`[--icon-foreground:theme(colors.amber.900)] [--icon-background:theme(colors.amber.100)]`,
		},
	},
});

type IconColor = 'blue' | 'amber';

export type IconProps = {
	icon: keyof typeof icons;
	color: IconColor;
} & React.ComponentPropsWithoutRef<'svg'>;

export function Icon({ color, icon, ...props }: IconProps) {
	let id = useId();
	let IconComponent = icons[icon];

	return (
		<StyledIcon
			color={color}
			aria-hidden="true"
			viewBox="0 0 32 32"
			fill="none"
			{...props}>
			<IconComponent id={id} color={color} />
		</StyledIcon>
	);
}

const gradients = {
	blue: [
		{ stopColor: '#0EA5E9' },
		{ stopColor: '#22D3EE', offset: '.527' },
		{ stopColor: '#818CF8', offset: 1 },
	],
	amber: [
		{ stopColor: '#FDE68A', offset: '.08' },
		{ stopColor: '#F59E0B', offset: '.837' },
	],
};

export function Gradient({ color = 'blue', ...props }) {
	return (
		<radialGradient
			cx={0}
			cy={0}
			r={1}
			gradientUnits="userSpaceOnUse"
			{...props}>
			{gradients[color].map((stop, stopIndex) => (
				<stop key={stopIndex} {...stop} />
			))}
		</radialGradient>
	);
}
