import { Disclosure } from '@headlessui/react';
import { OrderButtonProps, orderSuccessAtom } from './OrderButton';
import 'twin.macro';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useIsomorphicLayoutEffect as useLayoutEffect } from 'react-use';

import { atom, useAtom, Provider } from 'jotai';
import dynamic from 'next/dynamic';
import tw from 'twin.macro';
import { ChevronDownIcon } from '@heroicons/react/outline';
import { cssIf } from 'stitches.config';
import { trackGoal } from 'fathom-client';
import slugify from '@sindresorhus/slugify';

const OrderButton = dynamic(() => import('./OrderButton'), { ssr: false });

const couponCodeAtom = atom('');
const submittedCouponCodeAtom = atom('');
const couponDiscountAtom = atom(0);

type Coupons = { code: string; discount: number }[];
type OrderSummaryProps = Pick<
	OrderButtonProps,
	'priceInEuros' | 'productDescription'
> & {
	coupons?: Coupons;
};

const OrderSummary = ({
	priceInEuros,
	productDescription,
	coupons,
}: OrderSummaryProps) => {
	const [couponCode, setCouponCode] = useAtom(couponCodeAtom);
	const [submittedCode, setSubmittedCode] = useAtom(submittedCouponCodeAtom);
	const [discount, setDiscount] = useAtom(couponDiscountAtom);
	const [success] = useAtom(orderSuccessAtom);
	const id = useMemo(() => slugify(productDescription), [productDescription]);

	const submitCoupon = useCallback(
		async (e) => {
			e.preventDefault(e);
			const coupon = coupons?.find((coupon) => coupon.code === couponCode);
			if (coupon) {
				setDiscount(coupon.discount);
				setSubmittedCode(coupon.code);
			}
		},
		[couponCode]
	);
	return (
		<div
			aria-labelledby={`order-heading-${id}`}
			tw="bg-gray-50 px-4 py-6 sm:px-6">
			<h3 id={`order-heading-${id}`} tw="text-lg font-medium text-gray-900">
				{productDescription}
			</h3>
			{!success && (
				<form tw="mt-10" onSubmit={submitCoupon}>
					<label
						htmlFor={`discount-code-${id}`}
						tw="block text-sm font-medium text-gray-700">
						Rabattcode
					</label>
					<div tw="flex space-x-4 mt-1">
						<input
							type="text"
							id={`discount-code-${id}`}
							name="discount-code"
							value={couponCode}
							onChange={(e) => setCouponCode(e.target.value)}
							tw="block w-full border-gray-300 rounded-md shadow-sm focus:ring-brand-500 focus:border-brand-500 sm:text-sm"
						/>
						<button
							type="submit"
							tw="bg-gray-200 text-sm font-medium text-gray-600 rounded-md px-4 hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-brand-500">
							Einlösen
						</button>
					</div>
				</form>
			)}

			<dl tw="text-sm font-medium text-gray-500 mt-10 space-y-6">
				<div tw="flex justify-between">
					<dt>Zwischensumme</dt>
					<dd tw="text-gray-900">{priceInEuros} €</dd>
				</div>
				{!!discount && (
					<div tw="flex justify-between">
						<dt tw="flex">
							Rabatt
							<span tw="ml-2 rounded-full bg-gray-200 text-xs text-gray-600 py-0.5 px-2 tracking-wide">
								{submittedCode}
							</span>
						</dt>
						<dd tw="text-gray-900">-{priceInEuros * discount} €</dd>
					</div>
				)}
			</dl>

			<p tw="flex items-center justify-between text-sm font-medium text-gray-900 border-t border-gray-200 pt-6 mt-6">
				<span tw="text-base">Gesamtbetrag</span>
				<span tw="text-base">{priceInEuros - priceInEuros * discount} €</span>
			</p>
		</div>
	);
};

const checkoutOpenedEvent = process.env.NEXT_PUBLIC_FATHOM_PAYPAL_OPENED;

type OrderButtonCouponProps = OrderButtonProps & {
	coupons?: Coupons;
};
const _OrderButtonCoupon = ({
	priceInEuros,
	coupons,
	productDescription,
	successMessage,
}: OrderButtonCouponProps) => {
	const [discount] = useAtom(couponDiscountAtom);
	const discountedPrice = priceInEuros - priceInEuros * discount;
	const id = useMemo(() => slugify(productDescription), [productDescription]);
	const [openedOnce, setOpenedOnce] = useState(false);
	useEffect(() => {
		if (checkoutOpenedEvent && openedOnce) {
			trackGoal(checkoutOpenedEvent, 0);
		}
	}, [openedOnce]);
	return (
		<Disclosure as="div" id={id}>
			{({ open }) => {
				useLayoutEffect(() => {
					if (openedOnce) {
						const elt = document.getElementById(id);
						elt?.scrollIntoView({ block: 'center' });
					}
					if (open && !openedOnce) {
						setOpenedOnce(open);
					}
				}, [open, openedOnce]);
				return (
					<>
						<Disclosure.Button
							tw="text-left w-full flex justify-between items-start border border-brand-300 p-3 text-lg font-medium"
							css={cssIf(
								open,
								tw`rounded-t-md`,
								tw` rounded-md shadow-md shadow-brand-200`
							)}>
							<span tw="text-gray-900">Bezahlen</span>
							<span tw="ml-6 h-7 flex items-center text-gray-900 hover:text-gray-700">
								<ChevronDownIcon
									css={{
										...(open ? tw`-rotate-180` : tw`rotate-0`),
										...tw`h-6 w-6 transform`,
									}}
									aria-hidden="true"
								/>
							</span>
						</Disclosure.Button>
						<Disclosure.Panel tw="space-y-3">
							<OrderSummary
								{...{ priceInEuros, productDescription, coupons }}
							/>
							<OrderButton
								{...{ productDescription, successMessage }}
								priceInEuros={discountedPrice}
							/>
						</Disclosure.Panel>
					</>
				);
			}}
		</Disclosure>
	);
};
const OrderButtonCoupon = (props: OrderButtonCouponProps) => (
	<Provider>
		<_OrderButtonCoupon {...props} />
	</Provider>
);

export default OrderButtonCoupon;
