import LiteYouTubeEmbed from 'react-lite-youtube-embed';
import 'twin.macro';

// based from https://github.com/ibrahimcesar/react-lite-youtube-embed#option-1
const styles: CSS = {
	'.yt-lite': {
		backgroundColor: '#000',
		position: 'relative',
		display: 'block',
		contain: 'content',
		backgroundPosition: 'center center',
		backgroundSize: 'cover',
		cursor: 'pointer',
	},
	'.yt-lite::before': {
		content: "''",
		display: 'block',
		position: 'absolute',
		top: '0',
		backgroundImage:
			'url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAADGCAYAAAAT+OqFAAAAdklEQVQoz42QQQ7AIAgEF/T/D+kbq/RWAlnQyyazA4aoAB4FsBSA/bFjuF1EOL7VbrIrBuusmrt4ZZORfb6ehbWdnRHEIiITaEUKa5EJqUakRSaEYBJSCY2dEstQY7AuxahwXFrvZmWl2rh4JZ07z9dLtesfNj5q0FU3A5ObbwAAAABJRU5ErkJggg==)',
		backgroundPosition: 'top',
		backgroundRepeat: 'repeat-x',
		height: '60px',
		paddingBottom: '50px',
		width: '100%',
		transition: 'all 0.2s cubic-bezier(0, 0, 0.2, 1)',
	},
	'.yt-lite::after': {
		content: "''",
		display: 'block',
		paddingBottom: 'calc(100% / (16 / 9))',
	},
	'.yt-lite > iframe': {
		width: '100%',
		height: '100%',
		position: 'absolute',
		top: '0',
		left: '0',
	},
	'.yt-lite > .lty-playbtn': {
		width: '70px',
		height: '46px',
		backgroundColor: '#212121',
		zIndex: 1,
		opacity: 0.8,
		borderRadius: '14%',
		transition: 'all 0.2s cubic-bezier(0, 0, 0.2, 1)',
	},
	'.yt-lite:hover > .lty-playbtn': { backgroundColor: '#f00', opacity: 1 },
	'.yt-lite > .lty-playbtn:before': {
		content: "''",
		borderStyle: 'solid',
		borderWidth: '11px 0 11px 19px',
		borderColor: 'transparent transparent transparent #fff',
	},
	'.yt-lite > .lty-playbtn, .yt-lite > .lty-playbtn:before': {
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate3d(-50%, -50%, 0)',
	},
	'.yt-lite.lyt-activated': { cursor: 'unset' },
	'.yt-lite.lyt-activated::before, .yt-lite.lyt-activated > .lty-playbtn': {
		opacity: 0,
		pointerEvents: 'none',
	},
};

const YoutubeLiteEmbed = ({ embedId, title = '' }) => (
	<div css={styles}>
		<LiteYouTubeEmbed
			id={embedId}
			title={title}
			poster="maxresdefault"
			noCookie
		/>
	</div>
);

export default YoutubeLiteEmbed;
