import type {
	StructuredTextPropTypes,
	StructuredTextGraphQlResponseRecord,
} from 'react-datocms';
import renderBlock from './Block';
import MyStructuredText from './MyStructuredText';

type T = Omit<
	StructuredTextPropTypes<StructuredTextGraphQlResponseRecord>,
	'renderLinkToRecord' | 'renderInlineRecord' | 'renderBlocks'
>;

const MyStructuredTextWithBlocks = (props: T) => {
	return <MyStructuredText renderBlock={renderBlock} {...props} />;
};

export default MyStructuredTextWithBlocks;
