import { ErrorBoundary } from '@sentry/react';
import { PayPalScriptProvider, PayPalButtons } from '@paypal/react-paypal-js';
import { atom, useAtom } from 'jotai';
import { useAtomValue } from 'jotai/utils';
import 'twin.macro';
import { defaultRegions } from 'i18n';
import { useRouter } from 'next/router';
import { useCallback } from 'react';
import { CheckCircleIcon, XCircleIcon } from '@heroicons/react/solid';
import { trackGoal } from 'fathom-client';

const buttonIds = {
	'client-id': process.env.NEXT_PUBLIC_PAYPAL_CLIENT_ID as string,
	'merchant-id': process.env.NEXT_PUBLIC_PAYPAL_MERCHANT_ID as string,
};

export const orderSuccessAtom = atom<any>(null);
const orderErrorAtom = atom<any>(null);

const OrderSuccess = ({ successMessage }) => {
	const data = useAtomValue(orderSuccessAtom);
	if (!data) {
		return <></>;
	}
	return (
		<div tw="rounded-md bg-green-50 p-4">
			<div tw="flex">
				<div tw="flex-shrink-0">
					<CheckCircleIcon tw="h-5 w-5 text-green-400" aria-hidden="true" />
				</div>
				<div tw="ml-3">
					<h3 tw="(m-0 text-sm font-medium text-green-800)">
						Bezahlung erfolgreich!
					</h3>
					<div tw="(mt-2 text-sm text-green-700)">
						<p>{successMessage}</p>
					</div>
				</div>
			</div>
		</div>
	);
};

const OrderError = ({ sentryError }) => {
	const [error, setError] = useAtom(orderErrorAtom);
	if (!(error || sentryError)) {
		return <></>;
	}
	return (
		<div tw="rounded-md bg-red-50 p-4">
			<div tw="flex">
				<div tw="flex-shrink-0">
					<XCircleIcon tw="h-5 w-5 text-red-400" aria-hidden="true" />
				</div>
				<div tw="ml-3">
					<h3 tw="text-sm font-medium text-red-800">
						Bezahlung fehlgeschlagen
					</h3>
					<div tw="mt-2 text-sm text-red-700">
						<p>
							Bitte laden sie die PayPal Buttons neu um es nochmals zu
							versuchen. Ihr Zahlungsmittel wurde nicht belastet.
						</p>
					</div>
					<div tw="mt-4">
						<div tw="-mx-2 -my-1.5 flex">
							<button
								type="button"
								onClick={() => setError(null)}
								tw="bg-red-50 px-2 py-1.5 rounded-md text-sm font-medium text-red-800 hover:bg-red-100 focus:(outline-none ring-2 ring-offset-2 ring-offset-red-50 ring-red-600)">
								Buttons neu laden
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export type OrderButtonProps = {
	productDescription: string;
	priceInEuros: number;
	successMessage?: string;
};

const checkoutCompletedEvent = process.env.NEXT_PUBLIC_FATHOM_PAYPAL_COMPLETED;
const checkoutFailedEvent = process.env.NEXT_PUBLIC_FATHOM_PAYPAL_FAILED;
const checkoutCancelledEvent = process.env.NEXT_PUBLIC_FATHOM_PAYPAL_CANCELLED;

const OrderButton = ({
	priceInEuros,
	productDescription,
	successMessage,
}: OrderButtonProps) => {
	const [success, setSuccess] = useAtom(orderSuccessAtom);
	const [error, setError] = useAtom(orderErrorAtom);
	const { locale } = useRouter();

	const trackCompleted = useCallback(() => {
		if (checkoutCompletedEvent) {
			trackGoal(checkoutCompletedEvent, priceInEuros * 100);
		}
	}, [priceInEuros]);

	const trackFailed = useCallback(() => {
		if (checkoutFailedEvent) {
			trackGoal(checkoutFailedEvent, 0);
		}
	}, []);

	const trackCancelled = useCallback(() => {
		if (checkoutCancelledEvent) {
			trackGoal(checkoutCancelledEvent, 0);
		}
	}, []);

	if (success || error) {
		return (
			<>
				<OrderSuccess successMessage={successMessage} />
				<OrderError sentryError={null} />
			</>
		);
	}
	return (
		<ErrorBoundary fallback={({ error }) => <OrderError sentryError={error} />}>
			<PayPalScriptProvider
				options={{
					...buttonIds,
					currency: 'EUR',
					intent: 'capture',
					locale: `${locale!}_${defaultRegions[locale!]}`,
					'disable-funding': 'sepa',
					'enable-funding': 'giropay',
				}}>
				<PayPalButtons
					forceReRender={[priceInEuros, error]}
					style={{
						color: 'silver',
						label: 'pay',
						layout: 'vertical',
						height: 36,
					}}
					createOrder={async (data, actions) => {
						return actions.order.create({
							purchase_units: [
								{
									amount: {
										value: String(priceInEuros),
										currency_code: 'EUR',
									},
									description: productDescription,
								},
							],
							application_context: {
								shipping_preference: 'NO_SHIPPING',
							},
						});
					}}
					onApprove={async (data, actions) => {
						const order = actions.order;
						if (!order) {
							return;
						}
						return order.capture().then((details) => {
							setSuccess(details);
							trackCompleted();
						});
					}}
					onCancel={() => {
						trackCancelled();
					}}
					onError={(data) => {
						setError(data);
						trackFailed();
					}}
				/>
			</PayPalScriptProvider>
		</ErrorBoundary>
	);
};
export default OrderButton;
