import { Icon } from './icons/Icon';
import tw, { styled } from 'twin.macro';
import { useCallback } from 'react';

// adapted from Tailind UI Syntax Template: https://tailwindui.com/templates/syntax

const icons = {
	note: (props) => <Icon icon="lightbulb" color="blue" {...props} />,
	warning: (props) => <Icon icon="warning" color="amber" {...props} />,
};

const Container = styled('div', {
	...tw`my-8 flex rounded-3xl p-6`,
	variants: {
		type: {
			note: tw`bg-sky-50`,
			warning: tw`bg-amber-50`,
		},
	},
});

const Title = styled('p', {
	...tw`m-0 text-xl font-bold`,
	variants: {
		type: {
			note: tw`text-sky-900`,
			warning: tw`text-amber-900`,
		},
	},
});

const Body = styled('div', {
	...tw`prose prose-base mt-2.5`,
	'p:first-child': tw`mt-0`,
	'p:last-child': tw`mb-0`,
	variants: {
		type: {
			note: tw`text-sky-800 prose-a:text-sky-900 prose-code:text-sky-900 [--tw-prose-background:theme(colors.sky.50)] [--tw-prose-underline:theme(colors.sky.400)] [--tw-prose-bold:theme(colors.sky.900)] [--tw-prose-links:theme(colors.sky.900)]`,
			warning: tw`text-amber-800 prose-a:text-amber-900 prose-code:text-amber-900 [--tw-prose-background:theme(colors.amber.50)] [--tw-prose-underline:theme(colors.amber.400)] [--tw-prose-bold:theme(colors.amber.900)] [--tw-prose-links:theme(colors.amber.900)]`,
		},
	},
});

export type CalloutProps = {
	type: 'note' | 'warning';
	title: string;
	children: any;
};

const Callout = ({ type, title, children }: CalloutProps) => {
	let IconComponent = useCallback(styled(icons[type], tw`h-8 w-8 flex-none`), [
		type,
	]);

	return (
		<Container type={type}>
			<IconComponent />
			<div tw="ml-4 flex-auto">
				<div className="not-prose">
					<Title type={type}>{title}</Title>
				</div>
				<Body type={type}>{children}</Body>
			</div>
		</Container>
	);
};

export default Callout;
