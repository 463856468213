import { useCallback } from 'react';
import tw, { styled } from 'twin.macro';
import Link from 'components/SmartLink';

const knownAuthorsUrlKeys = [
	'unsplash_author_url',
	'unsplash_referrer',
	'author_url',
	'photographer_url',
	'photographer_website',
];

const knownCopyrightUrlKeys = [
	'unsplash_url',
	'unsplash_copyright_url',
	'copyright_url',
	'photographer_url',
	'photographer_website',
];

const findUrls = (data) => {
	const [, authorUrl = ''] =
		Object.entries<string>(data).find(([key]) =>
			knownAuthorsUrlKeys.includes(key)
		) ?? [];

	const [, copyrightUrl = ''] =
		Object.entries<string>(data).find(([key]) =>
			knownCopyrightUrlKeys.includes(key)
		) ?? [];

	return { authorUrl, copyrightUrl };
};

const textStyle = tw`text-gray-400! no-underline! font-normal!`;

const CopyrightNotice = (props) => {
	const { copyright, author, customData = {} } = props ?? {};
	const { authorUrl, copyrightUrl } = findUrls(customData);

	const Author = useCallback(styled(authorUrl ? Link : 'span', textStyle), [
		authorUrl,
	]);

	const Copyright = useCallback(
		styled(copyrightUrl ? Link : 'span', textStyle),
		[authorUrl]
	);

	const AuthorOrCopyright = useCallback(
		styled(authorUrl || copyrightUrl ? Link : 'span', textStyle),
		[authorUrl, copyrightUrl]
	);

	const authorAndCopyrightElement = author && copyright && (
		<>
			<Author href={authorUrl || undefined}>{author} /</Author>
			<Copyright
				href={copyrightUrl || undefined}>{` ©️ ${copyright}`}</Copyright>
		</>
	);

	const authorOrCopyrightElement = (author || copyright) && (
		<AuthorOrCopyright href={authorUrl || copyrightUrl || undefined}>
			{` ©️ ${author || copyright}`}
		</AuthorOrCopyright>
	);

	return authorAndCopyrightElement || authorOrCopyrightElement || <></>;
};

export default CopyrightNotice;
